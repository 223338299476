import {Page, SelectDropdown} from '@hconnect/uikit'
import {Box, Card, Paper} from '@material-ui/core'
import axios from 'axios'
/* eslint-disable */
import React, {useEffect} from 'react'

import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'

const triggerWsod = () => {
  console.log('called triggerWsod')
  const a = undefined
  // @ts-ignore
  const b = a.b.c
}

const triggerErrorInAsyncFunction = async () => {
  throw new Error('Some error')
}

const triggerStatus = async (statusCode: number) => {
  const url = `https://httpstat.us/${statusCode}`
  const api = axios.create()
  await api.get(url)
}

export const PageException: React.FC = () => {
  const global = useGlobal()

  const searchParams = new URLSearchParams(global.location.search)
  const selectedException: string | undefined = searchParams.get('what') ?? ''

  const dropdownList = [
    {key: undefined, title: 'Default (no error)'},
    {key: 'wsod', title: 'White screen of death'},
    {key: 'asyncerror', title: 'Trigger an error in an async function.'},
    {key: 'status400', title: 'Status 400'},
    {key: 'status500', title: 'Status 500'}
  ]

  useEffect(() => {
    switch (selectedException) {
      case 'wsod':
        triggerWsod()
        break
      case 'asyncerror':
        void triggerErrorInAsyncFunction()
        break
      case 'status400':
        void triggerStatus(400)
        break
      case 'status500':
        void triggerStatus(500)
        break
      default:
    }
  }, [selectedException])

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Trigger Exceptions'}>
        <Card>
          <Box p={2}>
            <h4>Trigger an UI exception of common UI issues.</h4>
            Rationale: Trigger an exception
            <ul>
              <li>manually</li>
              <li>in a Cypress test / cronjob</li>
            </ul>
            to check that the error is correctly logged to Azure Monitor. E.g., call it 10 times an
            hour with a cron job and check that 10 exceptions are logged on Azure Monitor.
          </Box>
        </Card>
        <br />

        <Paper>
          <Box padding={4}>
            <SelectDropdown
              label="Trigger an exception"
              options={dropdownList}
              renderItem={(item) => <>{item.title}</>}
              stringifyItem={(item) => `${item.title}`}
              onChange={(value: {key: string}) => {
                if (value.key) searchParams.set('what', value.key)
                else searchParams.delete('what')
                global.history.push(`?${searchParams.toString()}`)
              }}
              selectedItem={dropdownList.find((element) => element.key === selectedException)}
            />
          </Box>
        </Paper>
      </Page>
    </DefaultShell>
  )
}
