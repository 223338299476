import {
  apiBaseUrl,
  apiCheckApikeyUrl,
  apiGetActivationsUrl,
  apiGetBundleUrl,
  apiGetChartBundleUrl,
  apiGetCoverageUrl,
  apiGetCypressCoverageUrl,
  apiGetDeploymentsUrl,
  apiGetEslintUrl,
  apiGetJestCoverageUrl
} from '@hconnect/common/metrics/endpointsApi'
import {CheckApikey} from '@hconnect/common/metrics/reportTypes'
import {
  Activation,
  Bundle,
  ChartBundleSize,
  ChartOptions,
  Coverage,
  CypressCoverage,
  Deployment,
  PaginationQuery
} from '@hconnect/common/metrics/types'
import {Eslint} from '@hconnect/common/metrics/types/eslint'
import {JestCoverage} from '@hconnect/common/metrics/types/jest'
import axios, {AxiosInstance, AxiosRequestHeaders} from 'axios'
import queryString from 'query-string'

import {APIKEY} from './constants'

export const stringifyPaginationQuery = (query: PaginationQuery): string => {
  const orderBy = (query?.orderBy ?? []).map((l) => `${l.column}:${l.order}`).join(',')
  const filterBy = Object.entries(query?.filterBy ?? {})
    .map((k) => `${k[0]}:${k[1]}`)
    .join(',')
  const limit = query.limit
  const offset = query.offset

  const d = {}
  if (query.orderBy) d['orderBy'] = orderBy
  if (query.filterBy) d['filterBy'] = filterBy
  if (query.limit) d['limit'] = limit
  if (query.offset) d['offset'] = offset

  return queryString.stringify(d)
}

export class Backend {
  readonly basePath = 'localhost:'
  api: AxiosInstance
  constructor(getApikey: () => string) {
    this.api = axios.create({
      baseURL: apiBaseUrl(),
      timeout: 40000
    })

    this.api.interceptors.request.use((config) => {
      ;(config.headers as AxiosRequestHeaders).Authorization = `${APIKEY} ${getApikey()}`
      return config
    })
  }

  async getDeployments(data: PaginationQuery): Promise<Deployment[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<Deployment[]>(`${apiGetDeploymentsUrl()}?${s}`)
    return response.data
  }

  async getActivations(data: PaginationQuery): Promise<Activation[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<Activation[]>(`${apiGetActivationsUrl()}?${s}`)
    return response.data
  }

  async getCoverage(data: PaginationQuery): Promise<Coverage[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<Coverage[]>(`${apiGetCoverageUrl()}?${s}`)
    return response.data
  }

  async getEslint(data: PaginationQuery): Promise<Eslint[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<Eslint[]>(`${apiGetEslintUrl()}?${s}`)
    return response.data
  }

  async getJestCoverage(data: PaginationQuery): Promise<JestCoverage[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<JestCoverage[]>(`${apiGetJestCoverageUrl()}?${s}`)
    return response.data
  }

  async getCypressCoverage(data: PaginationQuery): Promise<CypressCoverage[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<CypressCoverage[]>(`${apiGetCypressCoverageUrl()}?${s}`)
    return response.data
  }

  async getBundle(data: PaginationQuery): Promise<Bundle[]> {
    const s = stringifyPaginationQuery(data)
    const response = await this.api.get<Bundle[]>(`${apiGetBundleUrl()}?${s}`)
    return response.data
  }

  async getBundleCharts(data: ChartOptions): Promise<ChartBundleSize> {
    const response = await this.api.get<ChartBundleSize>(`${apiGetChartBundleUrl(data)}`)
    return response.data
  }

  async checkApikey(): Promise<CheckApikey> {
    const response = await this.api.get<CheckApikey>(apiCheckApikeyUrl())
    return response.data
  }
}
