// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {makeStyles} from '@material-ui/core'
import * as d3 from 'd3'
import prettyBytes from 'pretty-bytes'
import React, {useEffect, useRef} from 'react'

interface ChartAxisXProps {
  scale: d3.ScaleLinear<number, number>
  // x and y are referring to the position of the x-axis
  x?: number
  y?: number
}

const useStyles = makeStyles((theme) => ({
  chartAxis: {
    ...theme.typography.subtitle1
  }
}))

const getYTicks = (scale) => {
  const maxValue = scale.domain()[1]
  const ticks = scale.ticks(5).filter((tick) => Number.isInteger(tick))
  if (ticks.length > 2) return [...ticks.slice(0, ticks.length - 1), maxValue]
  else return ticks
}

export const ChartBundleSizeInBytesAxisY: React.FC<ChartAxisXProps> = ({scale, x = 0, y = 0}) => {
  const ref = useRef<SVGSVGElement>(null)
  const {chartAxis} = useStyles()

  useEffect(() => {
    if (ref.current)
      d3.select(ref.current)
        .attr('transform', `translate(${x}, ${y})`)
        .attr('class', chartAxis)
        .call(
          d3
            .axisLeft(scale)
            .tickValues(getYTicks(scale))
            .tickFormat((x: number) => `${prettyBytes(x)}`)
        )
  }, [ref, scale, chartAxis, x, y])

  return <g ref={ref} />
}
