// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {COVERAGE_PACKAGES} from '@hconnect/common/config/constants'
import {JestCoverage} from '@hconnect/common/metrics/types/jest'
import {Grid, Page, SelectDropdown, Typography} from '@hconnect/uikit'
import {Box, Paper} from '@material-ui/core'
import * as d3 from 'd3'
import React from 'react'
import {useQuery} from 'react-query'

import {CenteredSpinner} from '../components/CenteredSpinner'
import {CoverageChart} from '../components/CoverageChart'
import {ErrorGeneric} from '../components/ErrorGeneric'
import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'

interface DatetimeValue {
  datetime: string
  value: number
}

interface CoverageTimeseries {
  linesCovered: DatetimeValue[]
  linesTotal: DatetimeValue[]
  linesPercentage: DatetimeValue[]
}

export const PageJestCharts: React.FC = () => {
  const global = useGlobal()

  const searchParams = new URLSearchParams(global.location.search)
  const selectedProduct: string | undefined = searchParams.get('product') ?? ''
  if (!selectedProduct) {
    searchParams.set('product', COVERAGE_PACKAGES[0])
    global.history.push(`?${searchParams.toString()}`)
  }

  const {
    isLoading,
    error,
    data: coverage
  } = useQuery<JestCoverage[], Error>(`jest-chart---${selectedProduct}`, async () => {
    const coverage = await global.backend.getJestCoverage({
      orderBy: [{column: 'createdAt', order: 'desc'}],
      filterBy: {product: selectedProduct},
      limit: 1000
    })

    return coverage
  })

  const linesCovered =
    coverage?.map((d) => ({
      datetime: d.createdAt,
      value: d.linesCovered
    })) ?? []

  const linesTotal =
    coverage?.map((d) => ({
      datetime: d.createdAt,
      value: d.linesTotal
    })) ?? []

  const linesPercentage =
    coverage?.map((d) => ({
      datetime: d.createdAt,
      value: d.linesTotal === 0 ? 0 : (100 * d.linesCovered) / d.linesTotal
    })) ?? []

  const coverageTimeseries: CoverageTimeseries = {linesTotal, linesCovered, linesPercentage}

  const dropDownList = COVERAGE_PACKAGES.map((product) => ({key: product, title: product}))

  let content
  if (error) content = <ErrorGeneric error={error} />
  else if (isLoading) content = <CenteredSpinner />
  else
    content = (
      <>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h3">{selectedProduct} covered lines</Typography>
            <CoverageChart
              data={coverageTimeseries.linesCovered}
              minValue={0}
              maxValue={d3.max(coverageTimeseries.linesTotal, (d) => d.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3">{selectedProduct} total lines</Typography>
            <CoverageChart data={coverageTimeseries.linesTotal} minValue={0} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3">percentage</Typography>
            <CoverageChart data={coverageTimeseries.linesPercentage} minValue={0} maxValue={100} />
          </Grid>
        </Grid>

        <SelectDropdown
          label="Product"
          options={dropDownList}
          renderItem={(item) => <>{item.title}</>}
          stringifyItem={(item) => `${item.title}`}
          onChange={(value: {key: string}) => {
            console.log(value)
            searchParams.set('product', value.key)
            global.history.push(`?${searchParams.toString()}`)
          }}
          selectedItem={dropDownList.find((element) => element.title === selectedProduct)}
        />
      </>
    )

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Coverage'}>
        <Paper>
          <Box padding={4}>{content}</Box>
        </Paper>
      </Page>
    </DefaultShell>
  )
}
