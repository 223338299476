import {Bundle} from '@hconnect/common/metrics/types'
import {DataTable, dateFormatter, Page, shortDayNameFormatter, timeFormatter} from '@hconnect/uikit'
import {Box, Card} from '@material-ui/core'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import {useQuery} from 'react-query'

import {ErrorGeneric} from '../components/ErrorGeneric'
import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'

export const PageBundle: React.FC = () => {
  const global = useGlobal()

  const {
    isLoading,
    error,
    data: coverage,
    isFetching
  } = useQuery<Bundle[], Error>('bundle', async () => {
    const data = await global.backend.getBundle({
      orderBy: [{column: 'createdAt', order: 'desc'}],
      limit: 30
    })
    return data
  })

  if (error) return <ErrorGeneric error={error} />

  const columns = [
    {
      key: 'createdAt',
      label: 'createdAt',
      sortable: false,
      numeric: false,
      customTemplate: (item: {createdAt: string | Date}) => (
        <>
          <b>
            {shortDayNameFormatter(item.createdAt)} {dateFormatter(item.createdAt)}
          </b>
          <br /> {timeFormatter(item.createdAt)}
        </>
      )
    },
    {
      key: 'createdBy',
      label: 'createdBy',
      sortable: false,
      numeric: false
    },
    {
      key: 'branch',
      label: 'branch',
      sortable: false,
      numeric: false
    },
    {
      key: 'fileExtension',
      label: 'fileExtension',
      sortable: false,
      numeric: false
    },
    {
      key: 'product',
      label: 'product',
      sortable: false,
      numeric: false
    },
    {
      key: 'sizeInBytes',
      label: 'sizeInBytes',
      sortable: false,
      numeric: false,
      customTemplate: (item: {sizeInBytes: number}) => <>{prettyBytes(item.sizeInBytes)}</>
    },
    {
      key: 'gzippedSizeInBytes',
      label: 'gzippedSizeInBytes',
      sortable: false,
      numeric: false,
      customTemplate: (item: {gzippedSizeInBytes: number}) => (
        <>{prettyBytes(item.gzippedSizeInBytes)}</>
      )
    },
    {
      key: 'gitSha1',
      label: 'gitSha1',
      sortable: false,
      numeric: false
    }
  ]

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Bundle'}>
        <Card>
          <Box p={2}>
            <h4>Use source map explorer on your local development machine to drill down.</h4>
            <pre>$ source-map-explorer build/static/js/*.js</pre>
          </Box>
        </Card>
        <br />

        <DataTable
          columns={columns}
          data={coverage ?? []}
          variant="compact"
          keyExtractor={(item: Bundle) => item.bundleId}
          loading={isLoading || isFetching}
        />
      </Page>
    </DefaultShell>
  )
}
