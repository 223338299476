export interface State {
  apiKey?: string
  apiKeyRead: boolean
  apiKeyWrite: boolean
}

export const initialState: State = {
  apiKeyRead: false,
  apiKeyWrite: false
}
