import {getBuildPublicUrl} from '@hconnect/common/hosting/azureUrls'
import {getGitlabPipelineUrl} from '@hconnect/common/hosting/gitlabUrls'
import {Activation} from '@hconnect/common/metrics/types'
import {DataTable, dateFormatter, shortDayNameFormatter, timeFormatter} from '@hconnect/uikit'
import React from 'react'
import {useQuery} from 'react-query'

import {ErrorGeneric} from '../components/ErrorGeneric'
import {useGlobal} from '../index.provider'

export interface IContainerActivationProps {
  product: string
  stage: string
}

interface AugmentedActivation extends Activation {
  url: string
}

export const ContainerActivation: React.FC<IContainerActivationProps> = ({product, stage}) => {
  const {backend} = useGlobal()

  const {
    isLoading,
    error,
    data: activations,
    isFetching
  } = useQuery<AugmentedActivation[], Error>(['activation', {product, stage}], async () => {
    const data = await backend.getActivations({
      orderBy: [{column: 'activatedAt', order: 'desc'}],
      filterBy: {product, stage},
      limit: 10
    })

    const augmentedData: AugmentedActivation[] = []
    for (const d of data) {
      const url = getBuildPublicUrl(product, stage, d.gitSha1)
      augmentedData.push({...d, url})
    }
    return augmentedData
  })

  if (error) return <ErrorGeneric error={error} />

  const columns = [
    {
      key: 'activatedAt',
      label: 'activatedAt',
      sortable: false,
      numeric: false,
      customTemplate: (item: AugmentedActivation) => (
        <>
          <b>
            {shortDayNameFormatter(item.activatedAt)} {dateFormatter(item.activatedAt)}
          </b>
          <br /> {timeFormatter(item.activatedAt)}
        </>
      )
    },
    {
      key: 'activatedBy',
      label: 'activatedBy',
      sortable: false,
      numeric: false
    },
    {
      key: 'branch',
      label: 'branch',
      sortable: false,
      numeric: false
    },
    {
      key: 'versionCode',
      label: 'versionCode',
      sortable: false,
      numeric: false
    },
    {
      key: 'gitSha1',
      label: 'gitSha1',
      sortable: false,
      numeric: false
    },
    {
      key: 'buildId',
      label: 'buildId',
      sortable: false,
      numeric: false,
      customTemplate: (item: AugmentedActivation) => (
        <a rel="noreferrer noopener" target="_blank" href={getGitlabPipelineUrl(item.buildId)}>
          {item.buildId}
        </a>
      )
    },
    {
      key: 'url',
      label: 'url',
      sortable: false,
      numeric: false,
      customTemplate: (item: AugmentedActivation) => (
        <a rel="noreferrer noopener" target="_blank" href={item.url}>
          Link
        </a>
      )
    }
  ]

  return (
    <DataTable
      columns={columns}
      data={activations ?? []}
      variant="compact"
      keyExtractor={(item: AugmentedActivation) => item.activationId}
      loading={isLoading || isFetching}
    />
  )
}
