export const getDashboardUrl = (): string => {
  return '/'
}
export const getErrorUrl = (): string => {
  return '/error'
}
export const getAdminUrl = (): string => {
  return '/apikey'
}
export const getDeploymentsUrl = (): string => {
  return '/deployments'
}
export const getActivationsUrl = (): string => {
  return '/activations'
}
export const getCoverageUrl = (): string => {
  return '/coverage'
}
export const getJestChartsUrl = (): string => {
  return '/jest/charts'
}

export const getCypressChartsUrl = (): string => {
  return '/cypress/charts'
}

export const getEslintUrl = (): string => {
  return '/eslint'
}

export const getJestCoverageUrl = (): string => {
  return '/jest'
}

export const getCypressCoverageUrl = (): string => {
  return '/cypress'
}

export const getBundleUrl = (): string => {
  return '/bundle'
}

export const getBundleChartsUrl = (): string => {
  return '/bundle/charts'
}

export const getExceptionUrl = (): string => {
  return '/exceptions'
}
