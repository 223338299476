import {PRODUCT_TO_CDN_NAME} from '../config/constants'

/**
 * Returns a public URL of the form https://<product>-<stage>-<gitSha1>.build.hconnect.digital
 */
export const getBuildPublicUrl = (product: string, stage: string, gitSha1: string): string => {
  const productNameOnCdn = PRODUCT_TO_CDN_NAME[product]

  return `https://${productNameOnCdn}-${stage}-${gitSha1}.build.hconnect.digital`
}
