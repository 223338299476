import {Box, Tooltip} from '@material-ui/core'
import {InfoOutlined} from '@material-ui/icons'
import {makeStyles} from '@material-ui/styles'
import React from 'react'

import {HCTheme} from '../HCTheme'

import Typography from './Typography'

export const ColoredInfoWidgetColors = {
  ok: HCTheme.palette.success.main,
  info: HCTheme.palette.warning.main,
  warning: HCTheme.palette.error.dark,
  other: HCTheme.palette.primary.main
}

const useStyles = makeStyles(() => ({
  ok: {
    backgroundColor: ColoredInfoWidgetColors.ok
  },
  info: {
    backgroundColor: ColoredInfoWidgetColors.info
  },
  warning: {
    backgroundColor: ColoredInfoWidgetColors.warning
  },
  other: {
    backgroundColor: ColoredInfoWidgetColors.other
  },
  alignIcon: {
    position: 'relative',
    top: '-4px'
  },
  alignTypography: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
}))

export enum ColoredInfoWidgetVariant {
  ok = 'ok',
  info = 'info',
  warning = 'warning',
  other = 'other'
}

interface ColoredInfoWidgetProps {
  variant: ColoredInfoWidgetVariant
  title: string
  message: string
  additionalInfo?: string
  tooltipMessage?: string
  visible?: boolean
}

export const ColoredInfoWidget: React.FC<ColoredInfoWidgetProps> = ({
  variant,
  title,
  message,
  additionalInfo,
  tooltipMessage,
  visible = true
}) => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="row" visibility={visible ? 'visible' : 'hidden'}>
      <Box minWidth={6} height={additionalInfo ? 64 : 48} className={classes[variant]} mr={1} />

      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography
          customVariant="formLabel"
          customColor="textPrimarySoft"
          classes={{root: classes.alignTypography}}
        >
          {title}
          {tooltipMessage && (
            <Tooltip title={tooltipMessage} placement="right" className={classes.alignIcon}>
              <InfoOutlined />
            </Tooltip>
          )}
        </Typography>
        <Typography variant="h3">{message}</Typography>
        {additionalInfo && (
          <Typography variant="caption" color="secondary">
            {additionalInfo}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
