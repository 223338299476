import {catchPromise} from '@hconnect/common/catchPromise'
import {CheckApikey} from '@hconnect/common/metrics/reportTypes'

import {APIKEY} from '../common/constants'
import {Global} from '../common/types'

export const handleApikeyChange = async (global: Global, value: string) => {
  localStorage.setItem(APIKEY, value)

  const {err, data} = await catchPromise<CheckApikey, unknown>(global.backend.checkApikey())
  if (!data) {
    console.error(err)
    return
  }
  global.setState({...global.state, apiKeyRead: data.read, apiKeyWrite: data.write})
}
