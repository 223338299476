import {LogoHProduceDarkGreen, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import React from 'react'

export const Footer: React.FC = () => (
  <Box
    component="footer"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    flexDirection="row"
    flexGrow={1}
    flexWrap="nowrap"
  >
    <div />
    <Typography variant="caption" color="secondary">
      Powered by
      <img style={{height: '22px', marginLeft: '8px'}} src={LogoHProduceDarkGreen} alt="" />
    </Typography>
  </Box>
)
