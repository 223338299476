import {getAzureStoragePublicUrl} from '@hconnect/common/automate/azureStorage'
import {COVERAGE_PACKAGES} from '@hconnect/common/config/constants'
import {Coverage} from '@hconnect/common/metrics/types'
import {DataTable, dateFormatter, Page, shortDayNameFormatter, timeFormatter} from '@hconnect/uikit'
import React from 'react'
import {useQuery} from 'react-query'

import {ErrorGeneric} from '../components/ErrorGeneric'
import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'

export const PageCoverage: React.FC = () => {
  const global = useGlobal()

  const {
    isLoading,
    error,
    data: coverage,
    isFetching
  } = useQuery<Coverage[], Error>('coverage', async () => {
    const data = await global.backend.getCoverage({
      orderBy: [{column: 'createdAt', order: 'desc'}],
      limit: 30
    })
    return data
  })

  if (error) return <ErrorGeneric error={error} />

  const columns = [
    {
      key: 'createdAt',
      label: 'createdAt',
      sortable: false,
      numeric: false,
      customTemplate: (item: {createdAt: string | Date}) => (
        <>
          <b>
            {shortDayNameFormatter(item.createdAt)} {dateFormatter(item.createdAt)}
          </b>
          <br /> {timeFormatter(item.createdAt)}
        </>
      )
    },
    {
      key: 'branch',
      label: 'branch',
      sortable: false,
      numeric: false
    },
    ...COVERAGE_PACKAGES.map((p) => ({
      key: `${p}LinesTotal`,
      label: `${p}`,
      sortable: false,
      numeric: false,
      customTemplate: (item) => (
        <>
          <b>{item[`${p}LinesCovered`]}</b>/{item[`${p}LinesTotal`]}
        </>
      )
    })),

    {
      key: 'gitSha1',
      label: 'gitSha1',
      sortable: false,
      numeric: false
    },

    {
      key: 'link',
      label: 'link',
      sortable: false,
      numeric: false,
      customTemplate: (item) => (
        <>
          {' '}
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={`${getAzureStoragePublicUrl('hconnectdocsdev')}/${item.bucket}/${
              item.product
            }/jest/lcov-report/index.html`}
          >
            Link
          </a>
        </>
      )
    }
  ]

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Coverage'}>
        <DataTable
          columns={columns}
          data={coverage ?? []}
          variant="compact"
          keyExtractor={(item: Coverage) => item.coverageId}
          loading={isLoading || isFetching}
        />
      </Page>
    </DefaultShell>
  )
}
