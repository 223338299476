import {getAzureStoragePublicUrl} from '@hconnect/common/automate/azureStorage'
import {Eslint} from '@hconnect/common/metrics/types/eslint'
import {DataTable, dateFormatter, Page, shortDayNameFormatter, timeFormatter} from '@hconnect/uikit'
import React from 'react'
import {useQuery} from 'react-query'

import {ErrorGeneric} from '../components/ErrorGeneric'
import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'

export const PageEslint: React.FC = () => {
  const global = useGlobal()

  const {
    isLoading,
    error,
    data: coverage,
    isFetching
  } = useQuery<Eslint[], Error>('eslint', async () => {
    const data = await global.backend.getEslint({
      orderBy: [{column: 'createdAt', order: 'desc'}],
      limit: 30
    })
    return data
  })

  if (error) return <ErrorGeneric error={error} />

  const columns = [
    {
      key: 'createdAt',
      label: 'createdAt',
      sortable: false,
      numeric: false,
      customTemplate: (item: {createdAt: string | Date}) => (
        <>
          <b>
            {shortDayNameFormatter(item.createdAt)} {dateFormatter(item.createdAt)}
          </b>
          <br /> {timeFormatter(item.createdAt)}
        </>
      )
    },
    {
      key: 'createdBy',
      label: 'createdBy',
      sortable: false,
      numeric: false
    },
    {
      key: 'branch',
      label: 'branch',
      sortable: false,
      numeric: false
    },
    {
      key: 'product',
      label: 'product',
      sortable: false,
      numeric: false
    },
    {
      key: 'errorCount',
      label: 'errorCount',
      sortable: false,
      numeric: false
    },
    {
      key: 'warningCount',
      label: 'warningCount',
      sortable: false,
      numeric: false
    },
    {
      key: 'gitSha1',
      label: 'gitSha1',
      sortable: false,
      numeric: false
    },

    {
      key: 'link',
      label: 'link',
      sortable: false,
      numeric: false,
      customTemplate: (item) => (
        <>
          {' '}
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={`${getAzureStoragePublicUrl('hconnectdocsdev')}/${item.bucket}/${
              item.product
            }/eslint/eslint.html`}
          >
            Link
          </a>
        </>
      )
    }
  ]

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Eslint'}>
        <DataTable
          columns={columns}
          data={coverage ?? []}
          variant="compact"
          keyExtractor={(item: Eslint) => item.eslintId}
          loading={isLoading || isFetching}
        />
      </Page>
    </DefaultShell>
  )
}
