import {
  apiCheckApikeyUrl,
  apiGetActivationsUrl,
  apiGetCypressCoverageUrl,
  apiGetDeploymentsUrl,
  apiGetJestCoverageUrl
} from '@hconnect/common/metrics/endpointsApi'
import {Card, ColoredInfoWidget, ColoredInfoWidgetVariant, Page, Typography} from '@hconnect/uikit'
import {Box, TextField} from '@material-ui/core'
import React from 'react'

import {APIKEY} from '../common/constants'
import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'
import {handleApikeyChange} from './PageAdmin.actions'

export const PageAdmin: React.FC = () => {
  const global = useGlobal()

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Admin'}>
        <Card>
          <>
            <Box p={4}>
              <TextField
                required
                fullWidth
                id="standard-required"
                label="Enter APIKEY"
                helperText={`*required. Please write an email to sebastian.walter@heidelbergcement.com to request the ${APIKEY}.`}
                value={global.localStorage.getItem(APIKEY)}
                onChange={(e) => void handleApikeyChange(global, e.target.value)}
                margin="normal"
              />
            </Box>
            <Box p={4}>
              <ColoredInfoWidget
                variant={
                  global.state.apiKeyRead
                    ? ColoredInfoWidgetVariant.ok
                    : ColoredInfoWidgetVariant.warning
                }
                title="Read access"
                message={`${global.state.apiKeyRead ? 'yes' : 'no'}`}
              />
            </Box>
            <Box p={4}>
              <ColoredInfoWidget
                variant={
                  global.state.apiKeyWrite
                    ? ColoredInfoWidgetVariant.ok
                    : ColoredInfoWidgetVariant.warning
                }
                title="Write access"
                message={`${global.state.apiKeyWrite ? 'yes' : 'no'}`}
              />
            </Box>

            <Box p={4}>
              <Typography variant="h3">Public self-serve API</Typography>
              <Typography variant="body2">
                All data displayed in this APP can be retrieved programmatically.
              </Typography>

              <pre>
                {`
curl '${
                  process.env.REACT_APP_API_BASE_URL
                }${apiCheckApikeyUrl()}' -H 'Authorization: APIKEY ${global.localStorage.getItem(
                  APIKEY
                )}'
curl '${
                  process.env.REACT_APP_API_BASE_URL
                }${apiGetActivationsUrl()}' -H 'Authorization: APIKEY ${global.localStorage.getItem(
                  APIKEY
                )}'
curl '${
                  process.env.REACT_APP_API_BASE_URL
                }${apiGetDeploymentsUrl()}' -H 'Authorization: APIKEY ${global.localStorage.getItem(
                  APIKEY
                )}'
curl '${
                  process.env.REACT_APP_API_BASE_URL
                }${apiGetJestCoverageUrl()}' -H 'Authorization: APIKEY ${global.localStorage.getItem(
                  APIKEY
                )}'
curl '${
                  process.env.REACT_APP_API_BASE_URL
                }${apiGetCypressCoverageUrl()}' -H 'Authorization: APIKEY ${global.localStorage.getItem(
                  APIKEY
                )}'
                
`}
              </pre>
            </Box>
          </>
        </Card>
      </Page>
    </DefaultShell>
  )
}
