import {getBuildPublicUrl} from '@hconnect/common/hosting/azureUrls'
import {getGitlabPipelineUrl} from '@hconnect/common/hosting/gitlabUrls'
import {Deployment} from '@hconnect/common/metrics/types'
import {DataTable, dateFormatter, shortDayNameFormatter, timeFormatter} from '@hconnect/uikit'
import React from 'react'
import {useQuery} from 'react-query'

import {ErrorGeneric} from '../components/ErrorGeneric'
import {useGlobal} from '../index.provider'

export interface ContainerDeploymentProps {
  product: string
  stage: string
}

interface AugmentedDeployment extends Deployment {
  url: string
}

export const ContainerDeployment: React.FC<ContainerDeploymentProps> = ({product, stage}) => {
  const {backend} = useGlobal()

  const {
    isLoading,
    error,
    data: deployments,
    isFetching
  } = useQuery<AugmentedDeployment[], Error>(['deployment', {product, stage}], async () => {
    const data = await backend.getDeployments({
      orderBy: [{column: 'deployedAt', order: 'desc'}],
      filterBy: {product, stage},
      limit: 10
    })

    const augmentedData: AugmentedDeployment[] = []
    for (const d of data) {
      const url = getBuildPublicUrl(product, stage, d.gitSha1)
      augmentedData.push({...d, url})
    }
    return augmentedData
  })

  if (error) return <ErrorGeneric error={error} />

  const columns = [
    {
      key: 'deployedAt',
      label: 'deployedAt',
      sortable: false,
      numeric: false,
      customTemplate: (item: AugmentedDeployment) => (
        <>
          <b>
            {shortDayNameFormatter(item.deployedAt)} {dateFormatter(item.deployedAt)}
          </b>
          <br /> {timeFormatter(item.deployedAt)}
        </>
      )
    },
    {
      key: 'deployedBy',
      label: 'deployedBy',
      sortable: false,
      numeric: false
    },
    {
      key: 'branch',
      label: 'branch',
      sortable: false,
      numeric: false
    },
    {
      key: 'versionCode',
      label: 'versionCode',
      sortable: false,
      numeric: false
    },
    {
      key: 'gitSha1',
      label: 'gitSha1',
      sortable: false,
      numeric: false
    },
    {
      key: 'buildId',
      label: 'buildId',
      sortable: false,
      numeric: false,
      customTemplate: (item: AugmentedDeployment) => (
        <a rel="noreferrer noopener" target="_blank" href={getGitlabPipelineUrl(item.buildId)}>
          {item.buildId}
        </a>
      )
    },
    {
      key: 'url',
      label: 'url',
      sortable: false,
      numeric: false,
      customTemplate: (item: AugmentedDeployment) => (
        <a rel="noreferrer noopener" target="_blank" href={item.url}>
          Link
        </a>
      )
    }
  ]

  return (
    <DataTable
      columns={columns}
      data={deployments ?? []}
      variant="compact"
      keyExtractor={(item: Deployment) => item.deploymentId}
      loading={isLoading || isFetching}
    />
  )
}
