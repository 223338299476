import {Page, Typography} from '@hconnect/uikit'
import React from 'react'

import {ErrorGenericProps} from '../components/ErrorGeneric'
import {TopNav} from '../components/TopNav'

import {DefaultShell} from './DefaultShell'

export const PageError: React.FC<ErrorGenericProps> = ({error}) => {
  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Error'}>
        <Typography variant="h3">There was an unrecoverable error!</Typography>
        <Typography variant="body2">{error.message}</Typography>
      </Page>
    </DefaultShell>
  )
}
