import {Shell, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import * as React from 'react'

import {Footer} from '../components/Footer'

export type DefaultShellProps = React.PropsWithChildren<{
  NavItems?: React.ReactChild
}>

export const DefaultShell: React.FC<DefaultShellProps> = ({children, NavItems}) => {
  return (
    <Shell
      boxed
      header={
        <>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h2">METRICS</Typography>
            {NavItems}
          </Box>
          <Box display="flex" flex={1} />
        </>
      }
      footer={<Footer />}
    >
      {children}
    </Shell>
  )
}
