import {catchPromise} from '@hconnect/common/catchPromise'
import {
  getActivationsUrl,
  getAdminUrl,
  getBundleChartsUrl,
  getBundleUrl,
  getCoverageUrl,
  getCypressChartsUrl,
  getCypressCoverageUrl,
  getDashboardUrl,
  getDeploymentsUrl,
  getEslintUrl,
  getExceptionUrl,
  getJestChartsUrl,
  getJestCoverageUrl
} from '@hconnect/common/metrics/endpointsUi'
import React from 'react'
import {useQuery} from 'react-query'

import {useGlobal} from './index.provider'
import {PageActivations} from './pages/PageActivations'
import {PageAdmin} from './pages/PageAdmin'
import {PageBundle} from './pages/PageBundle'
import {PageBundleCharts} from './pages/PageBundleCharts'
import {PageCoverage} from './pages/PageCoverage'
import {PageCypressCharts} from './pages/PageCypressCharts'
import {PageCypressCoverage} from './pages/PageCypressCoverage'
import {PageDashboard} from './pages/PageDashboard'
import {PageDeployments} from './pages/PageDeployments'
import {PageError} from './pages/PageError'
import {PageEslint} from './pages/PageEslint'
import {PageException} from './pages/PageException'
import {PageJestCharts} from './pages/PageJestCharts'
import {PageJestCoverage} from './pages/PageJestCoverage'
import {PageLoading} from './pages/PageLoading'

interface CheckApikeyResponse {
  read: boolean
  write: boolean
}

// eslint-disable-next-line complexity
export const App: React.FC = () => {
  const global = useGlobal()

  const {isLoading, error} = useQuery<CheckApikeyResponse, Error>(
    'apiKey',
    async () => {
      const {data, err} = await catchPromise(global.backend.checkApikey())
      if (!data) {
        throw new Error(`There was an error validating the API key. Please try again later. ${err}`)
      }
      return data
    },
    {
      onSuccess: (data) => {
        global.setState({
          ...global.state,
          apiKeyRead: data.read,
          apiKeyWrite: data.write
        })
      }
    }
  )

  if (error) return <PageError error={error} />
  if (isLoading) return <PageLoading />

  if (global.location.pathname === '/') global.history.push(getDeploymentsUrl())
  if (
    global.location.pathname !== getAdminUrl() &&
    !global.state.apiKeyRead &&
    !global.state.apiKeyWrite
  )
    global.history.push(getAdminUrl())

  switch (global.location.pathname) {
    case getDashboardUrl():
      return <PageDashboard />
    case getDeploymentsUrl():
      return <PageDeployments />
    case getCoverageUrl():
      return <PageCoverage />
    case getJestCoverageUrl():
      return <PageJestCoverage />
    case getJestChartsUrl():
      return <PageJestCharts />
    case getCypressCoverageUrl():
      return <PageCypressCoverage />
    case getCypressChartsUrl():
      return <PageCypressCharts />
    case getActivationsUrl():
      return <PageActivations />
    case getEslintUrl():
      return <PageEslint />
    case getBundleUrl():
      return <PageBundle />
    case getBundleChartsUrl():
      return <PageBundleCharts />
    case getAdminUrl():
      return <PageAdmin />
    case getExceptionUrl():
      return <PageException />
    default:
      return <PageDashboard />
  }
}
