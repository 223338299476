import {AppInsightsAdapter} from '@hconnect/common/logging'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'

import {App} from './App'
import {Provider} from './index.provider'

AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

ReactDOM.render(
  <Router>
    <Provider>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
)
