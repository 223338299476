import {Box} from '@material-ui/core'
import React from 'react'

export interface ErrorGenericProps {
  error: Error
}
export const ErrorGeneric: React.FC<ErrorGenericProps> = ({error}) => {
  console.log('called ErrorGeneric')
  return (
    <Box flex={1} display="flex" color="text.secondary" alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems={'center'}>
        {error.message}
      </Box>
    </Box>
  )
}
