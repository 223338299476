import React from 'react'

import {CenteredSpinner} from '../components/CenteredSpinner'

import {DefaultShell} from './DefaultShell'

export const PageLoading: React.FC = () => {
  return (
    <DefaultShell>
      <CenteredSpinner />
    </DefaultShell>
  )
}
