import {PRODUCTS, STAGES} from '@hconnect/common/config/constants'
import {Page} from '@hconnect/uikit'
import React from 'react'

import {TopNav} from '../components/TopNav'
import {ContainerActivation} from '../containers/ContainerActivation'

import {DefaultShell} from './DefaultShell'

export const PageActivations: React.FC = () => {
  const l: JSX.Element[] = []
  for (const stage of [...STAGES].reverse()) {
    for (const product of PRODUCTS) {
      l.push(
        <Page key={`${product}-${stage}`} title={`${product}-${stage}`}>
          <ContainerActivation product={product} stage={stage} />
        </Page>
      )
    }
  }

  l.push(
    <Page key="metricsapi-qa" title="metricsapi-qa">
      <ContainerActivation product="metricsapi" stage="qa" />
    </Page>
  )

  return <DefaultShell NavItems={<TopNav />}>{l}</DefaultShell>
}
