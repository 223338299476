import {
  getActivationsUrl,
  getAdminUrl,
  getBundleChartsUrl,
  getBundleUrl,
  getCypressChartsUrl,
  getCypressCoverageUrl,
  getDeploymentsUrl,
  getEslintUrl,
  getExceptionUrl,
  getJestChartsUrl,
  getJestCoverageUrl
} from '@hconnect/common/metrics/endpointsUi'
import {makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import {useGlobal} from '../index.provider'

const useStyles = makeStyles((theme) => ({
  base: {
    width: 'auto',
    minWidth: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 20,
    marginRight: 20,
    textTransform: 'none',
    ...theme.typography.h4,
    borderBottom: '4px solid transparent',

    '&:hover': {
      cursor: 'pointer',
      borderBottom: `4px solid ${theme.palette.primary.main}`
    }
  },
  selected: {
    borderBottom: `4px solid ${theme.palette.primary.main}`
  }
}))

interface TopNavItemProps {
  label: string
  onClick: (event: React.SyntheticEvent) => void
  selected: boolean
}

export const TopNavItem = ({label, onClick, selected}: TopNavItemProps) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.base, {[classes.selected]: selected})} onClick={onClick}>
      {label}
    </div>
  )
}

export const TopNav = () => {
  const global = useGlobal()

  if (!global.state.apiKeyRead) return <></>

  return (
    <>
      <TopNavItem
        label={'Deployments'}
        onClick={() => global.history.push(getDeploymentsUrl())}
        selected={global.location.pathname === getDeploymentsUrl()}
      />

      <TopNavItem
        label={'Activations'}
        onClick={() => global.history.push(getActivationsUrl())}
        selected={global.location.pathname === getActivationsUrl()}
      />

      {/* <TopNavItem*/}
      {/*  label={'Coverage'}*/}
      {/*  onClick={() => global.history.push(getCoverageUrl())}*/}
      {/*  selected={global.location.pathname === getCoverageUrl()}*/}
      {/*/ >*/}

      <TopNavItem
        label={'Jest Coverage'}
        onClick={() => global.history.push(getJestCoverageUrl())}
        selected={global.location.pathname === getJestCoverageUrl()}
      />

      <TopNavItem
        label={'Jest Charts'}
        onClick={() => global.history.push(getJestChartsUrl())}
        selected={global.location.pathname === getJestChartsUrl()}
      />

      <TopNavItem
        label={'Eslint'}
        onClick={() => global.history.push(getEslintUrl())}
        selected={global.location.pathname === getEslintUrl()}
      />

      <TopNavItem
        label={'Cypress Coverage'}
        onClick={() => global.history.push(getCypressCoverageUrl())}
        selected={global.location.pathname === getCypressCoverageUrl()}
      />

      <TopNavItem
        label={'Cypress Charts'}
        onClick={() => global.history.push(getCypressChartsUrl())}
        selected={global.location.pathname === getCypressChartsUrl()}
      />

      <TopNavItem
        label={'Bundle'}
        onClick={() => global.history.push(getBundleUrl())}
        selected={global.location.pathname === getBundleUrl()}
      />

      <TopNavItem
        label={'Bundle Charts'}
        onClick={() => global.history.push(getBundleChartsUrl())}
        selected={global.location.pathname === getBundleChartsUrl()}
      />

      <TopNavItem
        label={'Exceptions'}
        onClick={() => global.history.push(getExceptionUrl())}
        selected={global.location.pathname === getExceptionUrl()}
      />

      <TopNavItem
        label={'Admin'}
        onClick={() => global.history.push(getAdminUrl())}
        selected={global.location.pathname === getAdminUrl()}
      />
    </>
  )
}
