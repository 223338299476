import {Typography} from '@hconnect/uikit'
import {Box, CircularProgress} from '@material-ui/core'
import React from 'react'

export const CenteredSpinner: React.FC = () => {
  return (
    <Box flex={1} display="flex" color="text.secondary" alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems={'center'}>
        <CircularProgress />
        <Typography variant="body1">Loading ...</Typography>
      </Box>
    </Box>
  )
}
