import queryString from 'query-string'

import {ChartOptions} from './types'

export const apiBaseUrl = (): string => {
  return process.env.REACT_APP_API_BASE_URL ?? 'https://metricsapi.hcag.dev'
}

export const apiGetBuildConfigUrl = (): string => {
  return '/v1/build-config'
}

export const apiGetActivationsUrl = (): string => {
  return '/v1/activations'
}

export const apiGetDeploymentsUrl = (): string => {
  return '/v1/deployments'
}

export const apiGetCoverageUrl = (): string => {
  return '/v1/coverage'
}

export const apiGetEslintUrl = (): string => {
  return '/v1/eslint'
}

export const apiGetCypressCoverageUrl = (): string => {
  return '/v1/cypress'
}

export const apiGetJestCoverageUrl = (): string => {
  return '/v1/jest'
}

export const apiGetBundleUrl = (): string => {
  return '/v1/bundle'
}

export const apiCheckApikeyUrl = (): string => {
  return '/v1/check-apikey'
}

export const apiGetChartBundleUrl = (options?: ChartOptions): string => {
  let url = '/v1/chart/bundle'
  if (options) url = url + `?${queryString.stringify(options)}`
  return url
}

export const apiPostActivationsUrl = apiGetActivationsUrl
export const apiPostDeploymentsUrl = apiGetDeploymentsUrl
export const apiPostCoverageUrl = apiGetCoverageUrl
export const apiPostEslintUrl = apiGetEslintUrl
export const apiPostCypressCoverageUrl = apiGetCypressCoverageUrl
export const apiPostJestCoverageUrl = apiGetJestCoverageUrl
export const apiPostBundleUrl = apiGetBundleUrl
