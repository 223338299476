import {Page} from '@hconnect/uikit'
import React from 'react'

import {TopNav} from '../components/TopNav'

import {DefaultShell} from './DefaultShell'

export const PageDashboard: React.FC = () => {
  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Dashboard'}>Some content</Page>
    </DefaultShell>
  )
}
